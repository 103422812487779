<template>
	<v-menu
			ref="menu"
			v-model="menu"
			:close-on-content-click="false"
			transition="scale-transition"
			offset-y
			:nudge-top="hideDetails ? '': '21px'"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-text-field
					:hide-details="hideDetails"
					:dense="dense"
					v-model="rangeTimeText"
					:label="startText + (endText ? (' ~ ' + endText): '')"
					class="mr-2"
					:class="required ? 'asterisk': ''"
					:required="required"
					:rules="required ?[v => !!v || (startText + (endText ? (' ~ ' + endText): '') + '不能为空')]: []"
					readonly
					v-bind="attrs"
					v-on="on"
			>
				<template v-slot:append>
					<v-icon class="iconfont" size="24">
						icon-shijian
					</v-icon>
				</template>
			</v-text-field>
		</template>
		<div style="overflow: hidden">
			<time-picker
					:title="startText"
					:show-second="showSecond"
					:max="end"
					:value="start"
					@currentValue="setCurrentValue($event, 'begin')"
			/>
			<time-picker
					:title="endText ? endText: '-'"
					:show-second="showSecond"
					:min="start"
					:value="end"
					@currentValue="setCurrentValue($event, 'end')"
			/>
			<v-card style="border-top: 1px solid #E7E7E7; padding: 10px">
				<v-btn color="primary" @click="menu = false" outlined depressed>取消</v-btn>
				<v-btn color="primary" @click="confirm" depressed style="margin-left: 10px">确定</v-btn>
			</v-card>
		</div>
	</v-menu>
</template>

<script>

export default {
	components: {timePicker: () => import('./TimePicker.vue')},
	props: {
		dense: {
			type: Boolean,
			default: () => false
		},
		startText: {
			type: String,
			default: () => '开始时间'
		},
		endText: {
			type: String,
			default: () => '结束时间'
		},
		required: {
			type: Boolean,
			default: () => false
		},
		value: {
			type: String,
			default: ''
		},
		hideDetails: {
			type: Boolean,
			default: false
		},
		showSecond: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			menu: false,
			rangeTimeText: '',
			start: '9:00:00',
			end: '18:00:00'
		}
	},
	methods: {
		confirm() {
			this.rangeTimeText = this.start + " ~ " + this.end;
			this.$emit("change", this.rangeTimeText);
			this.menu = false;
		},
		setCurrentValue(v, t) {
			if (t === 'begin') {
				this.start = v;
			} else {
				this.end = v;
			}
		}
	},
	mounted() {
		this.rangeTimeText = this.value;
		if (this.rangeTimeText) {
			let array = this.rangeTimeText.split(" ~ ");
			this.start = array[0];
			this.end = array[1];
		}
	}
}
</script>

<style scoped lang="scss">
.c-title {
	color: var(--v-primary-base);
	float: right;
	text-align: center;
}

::v-deep {
	.v-sheet.v-card:not(.v-sheet--outlined) {
		box-shadow: none;
	}
}
</style>
