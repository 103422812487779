<template>
	<div>
		<common-table
				:itemScope="['lsell', 'mapMarker']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="园区"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:mapMarker="{ item }">
				<v-btn depressed color="primary" @click="mapMarker(item.code)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					业态规划
				</v-btn>
			</template>
		</common-table>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"
			/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/map.js';
import rangeTimePicker from "@/components/timePicker/rangeTimePicker.vue";
import editorText from "@/components/editorText/editorText.vue";
import qrCode from "@/components/qr-code/qr-code.vue"

export default {
	mixins: [commonCurd],
	components: {
		qrCode
	},
	data() {
		return {
			api,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: ''
			},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				// {label: '同步票型', icon: 'tongbu', event: 'syncTicketType'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入园区代码/名称', property: 'searchkey', width: '200px'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				},
				// {
				//   event: 'pcView',
				//   icon: 'diannaoduan',
				//   color: 'primary',
				//   tooltip: '电脑端'
				// }
			],
			// 表格头部字段
			headers: [
				{text: '园区代码', value: 'code'},
				{text: '园区名称', value: 'description'},
				{text: '业态规划', value: 'mapMarker'},
				{text: '中心坐标', value: 'coordinate'},
				{text: '排序号', value: 'seq'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "园区代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "园区名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'coordinate',
						connector: {
							props: {
								required: true,
								label: "中心坐标",
								rules: [
									v => !!v || '纬度、经度不能为空，用英文逗号“,”分隔',
									v => (/^(-?\d+(\.\d+)?),(-?\d+(\.\d+)?)$/.test(v)) || '坐标格式不正确，纬度、经度用英文逗号“,”分隔',
								],
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'businesstype',
						connector: {
							props: {
								required: true,
								label: "业态范围",
								arrayType: false,
								multiple: true,
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
				]
			}
		}
	},
	methods: {
		mapMarker(item) {
			this.$router.push({name: 'mapMarker', query: {value: item}});
		},
		// beforeSave(form) {
		//   if (!this.globalMethods.isEmpty(form.timeRange)) {
		//     let array = form.timeRange.split(" ~ ");
		//     form.openingtime = array[0];
		//     form.closetime = array[1];
		//   }
		// },
		// beforeEdit(form) {
		//   if (!this.globalMethods.isEmpty(form.openingtime) && !this.globalMethods.isEmpty(form.closetime)) {
		//     form.timeRange = form.openingtime + " ~ " + form.closetime;
		//   }
		// },
		beforeOpenAddDialog() {
			this.getCommonSelect();
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 13
				}
			}
		},
		otherRightOperate(event) {
			// if (event === this.rightConfig[0].event) {
			//   this.axios.post(this.syncTicketType).then((res) => {
			//     if (res.code === this.staticVal.Code.Success) {
			//       this.snackbar.success('同步成功');
			//     }
			//   });
			// }
		},
		// 通用下拉框数据
		getCommonSelect() {
			this.axios.post(
					this.select_data, {keyname: "mapbusinesstype"}
			).then((res) => {
						if (res?.data) {
							this.listDeploy.row[3].connector.props.items = res.data[0].values;
						}
					}
			);
		},
		// 编辑时票型下拉框不显示已经绑定的数据，因此需重新查找出已绑定的数据再加入到未绑定的下拉框
		// getTicketGroup(callback) {
		//   this.axios.post(
		//       this.select_data, {
		//         keyname: "ticketgroup",
		//         params: {key: 'ticketgroup', value: this.listDeploy.row[0].default}
		//       }
		//   ).then((response) => {
		//     if (response.code === this.staticVal.Code.Success) {
		//       if (callback) {
		//         callback(response.data[0].values[0].children);
		//       }
		//     }
		//   });
		// }
	},
	mounted() {
		this.getCommonSelect();
	}
}
</script>

<style scoped lang="scss">

</style>
