<template>
	<v-dialog persistent v-model="dialog" :width="328">
		<v-card>
			<div style="float: right; margin: 5px 10px 0 0">
				<v-icon size="24" @click="$emit('update:dialog', false)">iconfont icon-guanbi</v-icon>
			</div>
			<div style="padding: 24px">
				<div style="font-size: 18px; font-weight: bold">{{ title }}</div>
				<div class="mt-2 mb-2" style="min-height: 300px">
					<template v-if="base64">
						<v-img :src="base64Prefix + base64" :alt="subtitle"/>
					</template>
					<template v-else>
						<v-skeleton-loader type="image"/>
					</template>
				</div>
				<div class="mt-2 text-center" style="font-size: 18px; font-weight: bold;">
					{{ subtitle }}
				</div>
				<div class="mt-4 text-center">
					<v-btn depressed color="primary" @click="download">下载二维码</v-btn>
				</div>
			</div>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	props: {
		dialog: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: '二维码'
		},
		subtitle: {
			type: String,
			default: ''
		},
		// 链接参数 比如酒店id ,大组id
		param: {
			type: String,
			default: ''
		},
		// 路径枚举
		pathNo: {
			type: Number,
			default: 0
		},
		// 二维码类型,可用值:WXAPP,WXWEB
		type: {
			type: String,
			default: 'WXAPP'
		}
	},
	data() {
		return {
			base64Prefix: 'data:image/jpg;base64,',
			base64: ''
		}
	},
	mounted() {
		this.axios.post('/api/product/genqrcode', {param: this.param, pathNo: this.pathNo, type: this.type}).then((res) => {
			this.base64 = res.data.base64
		})
	},
	destroyed() {
		this.base64 = ''
	},
	methods: {
		download() {
			if (this.base64) {
				let link = document.createElement('a')
				link.href = this.base64Prefix + this.base64
				link.download = this.subtitle + '二维码.png'
				link.click()
			}
		}
	}
}
</script>

<style scoped>

</style>
